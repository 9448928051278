import axios from "axios";

const URL = process.env.REACT_APP_MEDIAPULSE_URL_V2

export type WorkOrder = {
  wo_no_seq: string,
  XMORDER_field_165: string
}

export default async function getWorkOrderList(transmissionIds: string[]): Promise<WorkOrder[]> {
  const {data} = await axios.get(`${URL}JmWorkOrderList`, {
    params: {
      query: `{"wo_no_seq":{$in:["${transmissionIds.join('","')}"]}}`,
      resultcolumns: `{"L":["XMORDER_field_165", "wo_no_seq"]}`
    },
    auth: {
      username: process.env.REACT_APP_MEDIAPULSE_USERNAME ?? '',
      password: process.env.REACT_APP_MEDIAPULSE_PASSWORD ?? ''
    }
  })

  return data?.['L']
}
