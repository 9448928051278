import React from 'react'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import {RecoilRoot} from 'recoil'
import {createRoot} from "react-dom/client";

const Root = () => {
  return <RecoilRoot>
    <App/>
  </RecoilRoot>
}

const container = document.getElementById(`root`) as HTMLElement
const root = createRoot(container)
root.render(<Root />)

serviceWorker.unregister()
