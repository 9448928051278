import React, { useEffect, useState } from "react";
import IUser from "../../models/User";
import { IFeedValue } from "../../models/Feeds";
import Menu from "../Menu/Menu";
import OlefGrid from "../OlefGrid/OlefGrid";
import { olefService } from "../Services/OlefService";
import { IResponse } from "../../Common";
import AlertError from "../AlertError/AlertError";
import "../OlefDetail/OlefDetail.scss";
import { useUserOlefStore } from "../stores/user.store";
import { Grid } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {useGetWorkOrderList} from "../../hooks/useGetWorkOrderList";

dayjs.extend(utc);

function Olefs() {
  const [feeds, setFeeds] = useState<IFeedValue[]>([])
  const [openError, setOpenError] = useState(false)
  const [transmissionIds, setTransmissionIds] = useState<string[]>([])
  const [xmOrders, setXmOrders] = useState<string[]>([])

  const {data: workOrderList} = useGetWorkOrderList(transmissionIds)

  useEffect(() => {
    const userJson = localStorage.getItem("user");
    const currentUser: IUser = userJson !== null ? JSON.parse(userJson) : {};

    if(currentUser) {
      updateGrid(loadCurrentDayStart(), loadCurrentDayEnd(), "", [], [])
    }
  }, [  ])

  function updateGrid(start: Date, end: Date, search: string, hubs: string[], xmOrders: string[]) {
    olefService
      .getFeeds(start, end, useUserOlefStore.getState()!.providerName, hubs)
      .then((response: IResponse) => {
        if (response.callSuccess) {
          const feeds = response.result
            .filter((feed: IFeedValue) => {
              if (search) {
                return (
                  (feed.broadcastType &&
                    feed.broadcastType
                      .toLowerCase()
                      .includes(search.toLowerCase())) ||
                  (feed.broadcaster &&
                    feed.broadcaster
                      .toLowerCase()
                      .includes(search.toLowerCase())) ||
                  (feed.name &&
                    feed.name.toLowerCase().includes(search.toLowerCase())) ||
                  (feed.type &&
                    feed.type.toLowerCase().includes(search.toLowerCase())) ||
                  (feed.wonosequence &&
                    feed.wonosequence
                      .toLowerCase()
                      .includes(search.toLowerCase()))
                  || (feed.description?.toLowerCase().includes(search.toLowerCase()))
                )
              } else {
                return true;
              }
            })
            .sort((a: IFeedValue, b: IFeedValue) => {
              return a.name.localeCompare(b.name);
            })
            .sort((a: IFeedValue, b: IFeedValue) => {
              if (a.name === b.name) {
                return (
                  new Date(a.lineup).getTime() - new Date(b.lineup).getTime()
                );
              }
            });

          setTransmissionIds(feeds.map((feed:IFeedValue) => feed.wonosequence))
          setFeeds(feeds)
          setXmOrders(xmOrders)
        } else {
          setOpenError(true)
        }
      });
  }

  function handleOpenError () {
    setOpenError(true)
  }

  function handleCloseError () {
    setOpenError(false)
  }

  function loadCurrentDayStart  () {
    return dayjs().utc().startOf("day").toDate();
  }

  function loadCurrentDayEnd  () {
    return dayjs().utc().endOf("day").toDate();
  }

    return (
      <div style={{ flexDirection: "column", flexWrap: "initial" }}>
        <Grid container={true}>
          <Menu
            updateGrid={updateGrid}
            Start={loadCurrentDayStart()}
            End={loadCurrentDayEnd()}
          />
          <OlefGrid feeds={feeds.filter((feed: IFeedValue) => {
            if (xmOrders.length > 0 && workOrderList) {
              return workOrderList.some(wo => wo.wo_no_seq === feed.wonosequence && xmOrders.includes(wo.XMORDER_field_165))
            }
            return true
          })} openError={handleOpenError} />

          <AlertError
            open={openError}
            handleClose={handleCloseError}
          />
        </Grid>
      </div>
    );
}

export default Olefs;
