import {useQuery} from "@tanstack/react-query";
import getWorkOrderList from "../components/Services/Mediapulse/getWorkOrderList";

export function useGetWorkOrderList(transmissionIds: string[]) {
  return useQuery({
    queryKey: ['getWorkOrder', transmissionIds],
    queryFn: () => getWorkOrderList(transmissionIds),
    enabled: transmissionIds.length > 0
  })
}
