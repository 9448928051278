import React from 'react'
import './App.scss'
import Header from './components/Shared/Header/Header'
import {useRecoilState} from 'recoil'
import {themeState} from "./atoms/theme"
import {createTheme, ThemeProvider} from "@mui/material"
import {deepmerge} from "@mui/utils"
import {getEurosportTheme} from "@shared/eurosport-ui"
import { CssBaseline } from '@mui/material'
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const App: React.FC = () => {
  const [theme, setTheme] = useRecoilState(themeState)

  const libTheme = React.useMemo(() => {
    return createTheme(deepmerge(getEurosportTheme(theme as 'dark' | 'light'), {components: {
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            backgroundColor: "#32323e",
            fontWeight: 500,
            fontSize: "1.25rem",
            paddingBottom: "32px",
            "&.card-title":{
              backgroundColor: "#BCC2FF",
              textTransform: "none"
            }
          }
        }
      },
      MuiTableCell:{
        styleOverrides: {
          root:{
            color: "white",
            fontSize: "14px"
          },
          head:{
            color: "white",
            fontSize: "14px"
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: '#1c1b1f',
            color: 'white'
          }
        }
      },
      MuiGrid: {
        styleOverrides: {
          root: {

          }
        }
      }
    }
  }))
  }, [theme])

  const queryClient = new QueryClient()

  return (
    // @ts-ignore
      <ThemeProvider theme={libTheme}>
        <QueryClientProvider client={queryClient}>
        <CssBaseline>
          <div className={`App ${theme}`}>
            <Header/>
          </div>
        </CssBaseline>
        </QueryClientProvider>
      </ThemeProvider>
  );
}

export default App;
